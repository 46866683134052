<template>
  <BaseDialog
    v-model="showDialog"
    is-cross-close
    :max-width="800"
  >
    <template #content>
      <!-- @complete="handleComplete" - был в <BaseImportUploader -->
      <BaseImportUploader
        ref="uploader"
        v-model="params"
        :url="urlToUpload"
        :file-type="fileType"
        @uploaded="handleStart"
      />
      <BaseParserDetails
        ref="detail"
        :category-id="categoryId"
        :entity-id="entityId"
        :object-type="objectType"
        :params.sync="params"
        @parse:completed="handleParserCompleted"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseParserDetails from '@/components/base/BaseParserDetails'
import BaseDialog from '@/components/base/BaseDialog'
import BaseImportUploader from '@/components/base/BaseImportUploader'
import importPanel from '@/components/mixins/importPanel'

export default {
  mixins: [importPanel],
  name: 'BaseImportPanel',
  components: { BaseImportUploader, BaseDialog, BaseParserDetails },
  props: {
    urlToUpload: String,
    fileType: String,
    extraData: Object,
    urlCheck: String,
    objectType: String,
    categoryId: Number,
    entityId: Number,
  },
  data () {
    return {
      params: this.extraData
    }
  },
  watch: {
    extraData: {
      handler () {
        this.params = this.extraData
      },
      deep: true
    }
  },
  methods: {
    handleParserCompleted () {
      this.$emit('parse:completed')
    }
  }
}
</script>

<style scoped>

</style>

