<template>
  <v-navigation-drawer
    dark
    width="330"
    permanent
    :temporary="false"
  >
    <v-sheet
      class="pa-4"
      style="background: #253e56"
    >
      <v-text-field
        dense
        v-model="search"
        :label="$t('region.search')"
        dark
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>
      <!--<v-checkbox
        dense
        v-model="caseSensitive"
        dark
        hide-details
        :label="$t('region.caseSensitive')"
      ></v-checkbox>-->
    </v-sheet>
    <div class="pa-2">
      <v-btn
        small
        outlined
        block
        @click="handleCreate(null)"
      >{{ $t('region.create') }}
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        class="mt-1"
        small
        outlined
        block
        @click="handleUpload"
      >{{ $t('region.upload') }}
        <v-icon small>mdi-list</v-icon>
      </v-btn>
    </div>
    <v-card-text :key="key">
      <v-treeview
        dense
        :items="tree"
        item-key="id"
        :open.sync="localOpen"
        activatable
        :search="search"
        :filter="filter"
        :active="localSelected"
        @update:active="handleUpdateSelected"
        return-object
        hoverable
        transition
      >
        <template v-slot:label="{ item, open }">
          <!-- Выводим id и label в нужном порядке -->
          <div class="flex align-center">
            <span class="mr-1"><b>ID: {{ item.id }}</b></span>
            <span>{{ item.label }}</span>
          </div>
        </template>

        <template v-slot:prepend="{ item, open }">
          <v-icon
            small
            v-if="item.children"
          >
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
        </template>

        <template v-slot:append="{ item, open }">
          <v-btn
            v-if="!item.is_leaf"
            icon
            small
            @click="handleCreate(item.id)"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="handleCategoryDelete(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </v-card-text>
    <!-- старая импорт панель <RegionImportPanel
          v-model="importDialog"
          :parent-id="value"
        />-->
    <BaseDialog
      v-model="importDialog"
      is-cross-close
      label="Менеджер импорта"
      without-sides-padding
      little-label
      without-bot-padding
    >
      <template #subtitle>
        <div class="text-h4 font-weight-bold">Регионов</div>
      </template>
      <template #content>
        <BaseImportPanel
          :file-type="fileType"
          :next-step-start-after="nextStepStartAfter"
          :previous-step-end-after="previousStepEndAfter"
          :params-to-upload="paramsToUpload"
          @parse:completed="handleParserCompleted"
        />
      </template>
    </BaseDialog>
    <BaseDialog
      role="delete"
      :max-width="350"
      :text="$t('message.deleteRegion')"
      v-model="isDeleteDialog"
      @confirm="deleteRegion"
      is-confirm-btn
      is-cancel-btn
    />
  </v-navigation-drawer>
</template>

<script>
import { deepEqual } from '@/components/utils/common'
import Region from '@/components/structures/region'
import RegionImportPanel from '@/components/views/account/region/detail/RegionImportPanel'
import BaseDialog from '@/components/base/BaseDialog'
import BaseImportPanel from '@/components/base/baseImportPanel/BaseImportPanel'

const PARSING_TYPE = 'region'
const COMMAND_TYPE = 'parsing'

export default {
  name: 'SideBar',
  components: { BaseImportPanel, RegionImportPanel, BaseDialog },
  props: {
    value: Number
  },
  data () {
    return {
      search: null,
      localSelected: [],
      localOpen: [],
      key: 0,
      editDialog: false,
      deleteDialog: false,
      importDialog: false,
      isDeleteDialog: false,
      deleteId: null,
      fileType: 'geojson',
      nextStepStartAfter: 1,
      previousStepEndAfter: 3,
      paramsToUpload: {
        commandType: COMMAND_TYPE,
        params: {
          parsingType: PARSING_TYPE,
          parentId: this.value
        }
      }
    }
  },
  computed: {
    tree () {
      this.key++
      return this.$store.getters['region/getTree']
    },
    filter () {
      return (item, search, textKey) => {
        return this.categoryToFilter ? item.type === this.categoryToFilter : true
      }
    }
  },
  watch: {
    value: {
      async handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal) && this.value) {
          await this.$nextTick(() => {
            let activeObject = this.$store.getters['region/getListItemById'](this.value)

            if (activeObject) {
              this.localSelected = [activeObject]
              this.localOpen.push(this.$store.getters['region/getListItemById'](activeObject.parent_id))
            }
          })
        }
        return true
      },
      deep: true,
      immediate: true
    },
    tree: {
      async handler () {
        return this.$nextTick(() => {
          if (this.localSelected.length) {
            let id = this.localSelected[0].id
            if (!this.$store.getters['region/getListItemById'](id)) {
              this.localSelected = []
              id = null
            }
          }
        })
      },
      deep: true
    }
  },
  methods: {
    handleUpload () {
      this.importDialog = true
    },
    handleUpdateSelected (data) {
      this.localSelected = data
      this.$emit('input', this.localSelected.length ? this.localSelected[0].id : null)
    },
    handleCategoryDelete (data) {
      this.isDeleteDialog = true
      this.deleteId = data.id
    },
    async handleParserCompleted () {
      this.importDialog = false
      return this.$store.dispatch('region/fetch')
    },
    async handleCreate (data) {
      const createdRegion = await this.$store.dispatch('region/updateCategory', new Region({ parent_id: data }))
      return this.$nextTick(() => {
        this.handleUpdateSelected([createdRegion])
      })
    },
    async deleteRegion () {
      return await this.$store.dispatch('region/deleteCategory', this.deleteId)
    }
  },
  mounted () {
    this.search = ''
  }
}
</script>

<style scoped>

</style>
