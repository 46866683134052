<template>
  <div>
    <v-tabs>
      <v-tab>Инфо</v-tab>
      <v-tab v-if="$route.name === 'region'">Карта</v-tab>
      <v-tab-item>
        <v-container
          v-if="value"
          fluid
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card
                outlined
              >
                <v-card-title>{{ $t('region.nameRegion') }}</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="detail.properties.label"
                    dense
                    outlined
                    :disabled="!isAdmin"
                    :label="$t('region.enterRegion')"
                  ></v-text-field>
                  <v-text-field
                    v-model="detail.properties.parent_id"
                    dense
                    outlined
                    :disabled="!isAdmin"
                    label="ID родительской папки"
                    :error-messages="errorMessages"
                    @input="getTyped"
                    @blur="clearInput"
                  ></v-text-field>

                  <v-card
                    outlined
                    width="150"
                  >
                    <v-card-text class="d-flex flex-column align-center">
                      <BaseColorPicker
                        v-model="detail.properties.style"
                        type="region"
                        class="mt-1 mb-1"
                        :disabled="!isAdmin"
                      />
                      <div>{{ $t('passport.colorOnMap') }}</div>
                    </v-card-text>
                  </v-card>
                  <v-checkbox
                    v-model="detail.properties.is_leaf"
                    dense
                    class="mr-2"
                    :label="$t('region.is_leaf')"
                  ></v-checkbox>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="keyMap">
        <BaseWrapper>
          <template #content>
            <BaseGeometry
              v-if="detail"
              :value="detail"
              :styles="detail.properties.style"
              geometryType="region"
              @loaded="loadedGeometry"
            />
          </template>
        </BaseWrapper>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import BaseCoordinates from '@/components/base/BaseCoordinates'
import BaseImportPanel from '@/components/base/BaseImportPanel'
import BaseColorPicker from '@/components/base/BaseColorPicker'
import BaseGeometry from '@/components/base/BaseGeometry'
import BaseWrapper from '@/components/base/BaseWrapper'

export default {
  name: 'DetailView',
  components: { BaseImportPanel, BaseCoordinates, BaseColorPicker, BaseGeometry, BaseWrapper },
  props: {
    value: Object,
    childrenIds: Array
  },
  data () {
    return {
      detail: this.value,
      keyMap: 0,
      hasTyped: false,
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
        this.$set(this, 'keyMap', this.keyMap + 1)
      },
      deep: true
    }
  },
  computed: {
    errorMessages() {
      const parentId = this.detail.properties.parent_id;
      if (this.hasTyped && this.childrenIds.includes(parseInt(parentId))) {
        return ["Недопустимый ID"];
      }
      return [];
    },
    isAdmin () {
      return this.$can('admin', null)
    }
  },
  methods: {
    getTyped () {
      this.hasTyped = true
    },
    clearInput () {
      if (this.errorMessages.length) {
        this.detail.properties.parent_id = null
      }
    },
    async loadedGeometry (data) {
      this.$set(this, 'detail', data)
      this.$emit('input', this.detail)
    }
  }
}
</script>

<style scoped>
</style>
