<template>
  <div>
    <BasePreloader v-if="!isMounted"/>
    <slot
      v-else
      name="content"
    />
  </div>
</template>

<script>
import BasePreloader from '@/components/base/UI/BasePreloader'

export default {
  name: 'BaseWrapper',
  components: { BasePreloader },
  props: {
    timeout: {
      default: 100,
      type: Number
    }
  },
  data () {
    return {
      isMounted: false
    }
  },
  async mounted () {  //note: костыльный вариант
    setTimeout(() => {
      this.isMounted = true
    }, this.timeout)
  }
}
</script>