export const styles = {
  // стили выбранных объектов
  selectedStyle: {
    object: {
      weight: 4,
    },
    passport: {
      weight: 4,
      fill: false
    },
    region: {
      weight: 4,
      fill: false
    }
  },
  defaultStyle: {
    passport: {
      fill: true,
      color: '#000000',
      weight: 2
    }
  }
}
