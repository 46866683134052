<template>
  <BaseDetail :is-loading="loading">
    <template #sidebar>
      <SideBar
        v-model="selectedItem"
        @input="handleSelectedItem"
      />
    </template>
    <template #header>
      <BaseHeader
        v-show="region"
        @save="handleSave"
        :delete-btn="false"
        :close-btn="false"
      />
    </template>
    <template
      #content
      v-if="region"
    >
      <DetailView
        v-model="region"
        :children-ids="childrenIds"
      />
    </template>
    <template
      #content
      v-else
    >
      <v-card
        width="100%"
        height="100%"
        class="d-flex align-start justify-start pa-3"
      >
        <BaseAlert
          type="info"
          dense
          icon="mdi-arrow-bottom-left"
          text="Выберите регион из списка"
        />
      </v-card>
    </template>
  </BaseDetail>
</template>

<script>
import SideBar from '@/components/views/account/region/detail/SideBar.vue'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseDetail from '@/components/base/BaseDetail'
import DetailView from '@/components/views/account/region/detail/DetailView.vue'
import loading from '@/components/mixins/loading'
import BaseAlert from '@/components/base/UI/BaseAlert'
import store from '@/store'

export default {
  mixins: [loading],
  name: 'Detail',
  components: { DetailView, BaseDetail, SideBar, BaseHeader, BaseAlert },
  data () {
    return {
      selectedItem: null,
      isLoading: {
        region: false
      },
      region: null
    }
  },
  computed: {
    regionLoading () {
      return this.$store.getters['region/isLoading']
    },
    childrenIds () {
      let result = []
      if (this.region) {
        result = this.$store.getters['region/getIdsByParentId'](this.region.properties.id)
      }
      return result
    }
  },
  watch: {
    regionLoading (val) {
      this.isLoading.region = val
    }
  },
  methods: {
    async handleSave () {
      return this.$store.dispatch('region/updateCategory', this.region)
    },
    async handleSelectedItem (data) {
      if (data) {
        this.region = await store.dispatch('server/get', {
          url: 'region/detail/' + data
        })
      } else {
        this.region = null
      }
    },
    handleChooseRegion () {
      this.isRegion = !this.isRegion
    }
  }
}
</script>

<style scoped>
  .nav {
    background: cadetblue;
  }
  .content {
    background: #EBF3FA;
  }
</style>
