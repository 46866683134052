var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"dark":"","width":"330","permanent":"","temporary":false}},[_c('v-sheet',{staticClass:"pa-4",staticStyle:{"background":"#253e56"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('region.search'),"dark":"","flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"small":"","outlined":"","block":""},on:{"click":function($event){return _vm.handleCreate(null)}}},[_vm._v(_vm._s(_vm.$t('region.create'))+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"mt-1",attrs:{"small":"","outlined":"","block":""},on:{"click":_vm.handleUpload}},[_vm._v(_vm._s(_vm.$t('region.upload'))+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-list")])],1)],1),_c('v-card-text',{key:_vm.key},[_c('v-treeview',{attrs:{"dense":"","items":_vm.tree,"item-key":"id","open":_vm.localOpen,"activatable":"","search":_vm.search,"filter":_vm.filter,"active":_vm.localSelected,"return-object":"","hoverable":"","transition":""},on:{"update:open":function($event){_vm.localOpen=$event},"update:active":_vm.handleUpdateSelected},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('div',{staticClass:"flex align-center"},[_c('span',{staticClass:"mr-1"},[_c('b',[_vm._v("ID: "+_vm._s(item.id))])]),_c('span',[_vm._v(_vm._s(item.label))])])]}},{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.children)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.is_leaf)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleCreate(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleCategoryDelete(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1),_c('BaseDialog',{attrs:{"is-cross-close":"","label":"Менеджер импорта","without-sides-padding":"","little-label":"","without-bot-padding":""},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('div',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Регионов")])]},proxy:true},{key:"content",fn:function(){return [_c('BaseImportPanel',{attrs:{"file-type":_vm.fileType,"next-step-start-after":_vm.nextStepStartAfter,"previous-step-end-after":_vm.previousStepEndAfter,"params-to-upload":_vm.paramsToUpload},on:{"parse:completed":_vm.handleParserCompleted}})]},proxy:true}]),model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}}),_c('BaseDialog',{attrs:{"role":"delete","max-width":350,"text":_vm.$t('message.deleteRegion'),"is-confirm-btn":"","is-cancel-btn":""},on:{"confirm":_vm.deleteRegion},model:{value:(_vm.isDeleteDialog),callback:function ($$v) {_vm.isDeleteDialog=$$v},expression:"isDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }