<template>
  <BaseDialog
    is-cross-close
    v-model="showDialog"
  >
    <template #content>
      <!-- в <BaseImportUploader было @complete="handleComplete"  -->
      <BaseImportUploader
        ref="uploader"
        v-model="params"
        url="filemanager/file/upload"
        @uploaded="handleStart"
        file-type="geojson"
      />
      <BaseParserDetails
        ref="detail"
        :parent-id="parentId"
        :object-type="'region'"
        :params.sync="params"
        @parse:completed="handleParserCompleted"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseParserDetails from '@/components/base/BaseParserDetails'
import BaseDialog from '@/components/base/BaseDialog'
import BaseImportUploader from '@/components/base/BaseImportUploader'
import importPanel from '@/components/mixins/importPanel'

export default {
  mixins: [importPanel],
  name: 'RegionImportPanel',
  components: { BaseImportUploader, BaseDialog, BaseParserDetails },
  props: {
    parentId: Number
  },
  methods: {
    async handleParserCompleted () {
      return this.$store.dispatch('region/fetch')
    }
  },
  watch: {
    parentId () {
      this.params.parentId = this.parentId
    }
  },
  created () {
    this.params = {
      commandType: 'parsing',
      params: {
        parentId: this.parentId,
        objectType: 'region'
      }
    }
  }
}
</script>

<style scoped>

</style>
