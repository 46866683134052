<template>
  <div v-if="value">
    <v-avatar
      @click="handleClickAvatar"
      class="mr-2"
      :color="color"
      width="40"
      height="40"
      min-width="40"
    >
    </v-avatar>
    <BaseDialog
      :value="isOpen"
      :max-width="400"
      label="Выберите цвет"
      is-cross-close
      @input="toggleOpen"
      @confirm="toggleOpen"
    >
      <template #content>
        <v-color-picker
          mode="hexa"
          class="mt-1 mb-1 d-flex justify-center flex-column"
          v-model="color"
          width="300"
          flat
        ></v-color-picker>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { styles } from '@/components/views/account/map/detail/common/mapStyles'
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'BaseColorPicker',
  components: { BaseDialog },
  props: {
    value: Object,
    type: String,
    disabled: Boolean
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    color: {
      get () {
        return this.value.color
      },
      set (val) {
        this.$emit('input', { ...this.value, color: val })
      }
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
      this.$emit('toggleDialog', this.isOpen)
    },
    handleClickAvatar () {
      if (!this.disabled) {
        this.toggleOpen()
      }
    }
  },
  created () {
    if (!this.value) {
      this.$emit('input', styles.defaultStyle[this.type])
    }
  }
}
</script>

<style scoped>

</style>
