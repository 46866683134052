class Region {
  constructor (props) {
    this.type = 'Feature'
    this.properties = {
      id: null,
      parent_id: props && props.parent_id ? props.parent_id : null,
      style: { color: '#000000', weight: 2, fill: true },
      label: 'Новый субъект',
      is_leaf: false
    }
    this.geometry = null
  }
}

export default Region
